<template>
<v-container>
  <v-row>
    <v-col cols="12">

      <v-card>
        <v-card-title primary-title>
          Consulta de Documentos
          <v-spacer></v-spacer>
          <v-btn color="success" small @click="consultar()"><v-icon>search</v-icon></v-btn>

          <v-btn
            class="ma-2"
            small dark color="warning"
            @click="exportar()">
            <v-icon>save_alt</v-icon>
          </v-btn>

        </v-card-title>

        <v-card-text>
          <v-row>

            <!-- FECHA INICIAL -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date1"
                    label="Fecha Inicial"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
              </v-menu>
            </v-col>

            <!-- FECHA FINAL -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2"
                    label="Fecha Final"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>

            <!-- TIPODOC  -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-select
                :items="tipodocs"
                v-model="tipodoc"
                label="Tipo documento"
                @change="consultar"
              ></v-select>
            </v-col>

            <!-- BUSCAR -->
            <v-col cols="12" xl="4" lg="4" md="3" sm="3" xs="3">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Buscar "
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <!-- DATATABLE -->
            <v-col cols="12">
              <v-data-table
                :loading="loading"
                loading-text="Cargando..."
                :headers="headers"
                :items="Docum"
                :items-per-page="longitud"
                class="elevation-1"
                fixed-header
                height="350"
                :search="search"
                hide-default-footer
                dense
              >
                <template v-slot:item="{ item }" >
                  <tr v-on:dblclick="edit(item)">
                    <td>{{ item.numdoc}}</td>

                    <!-- <td>{{ item.Tipodoc}}</td> -->
                    <td>{{ item.fecha}}</td>
                    <!-- <td class="d-flex justify-end">{{ item.importe}}</td>
                    <td>{{ item.descuento}}</td>
                    <td>{{ item.impuesto1}}</td> -->
                    <td align= 'end'>{{ item.total}}</td>

                    <td>{{ item.divisa}}</td>
                    <!-- <td>{{ item.Tc}}</td> -->

                    <td v-if="item.status == 0" :style="{color: getColor(item.status) }">Abierta</td>
                    <td v-if="item.status == 9" :style="{color: getColor(item.status) }">Pedida</td>
                  </tr>
               </template>
              </v-data-table>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import excelMixin from '@/mixins/excelMixin.js'
var accounting = require('accounting')
var base64 = require('base-64')

export default {
  mixins: [excelMixin],

  data () {
    return {
      // FECHAS
      date1: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),

      menu1: false,
      menu2: false,

      // SELECTOR TIPODOC
      tipodocs: ['Pedido', 'Cotización'],
      tipodoc: 'Pedido',
      e1: 'Pedidos',
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      longitud: 5,

      numcli: '',
      numalm: '',

      headers: [
        { text: 'ID', align: 'start', value: 'numdoc' },
        // { text: 'Tipo',  align: 'end'  ,           value: 'Tipodoc' },
        { text: 'Fecha', align: 'start', value: 'fecha' },
        // { text: 'Importe',   align: 'rigth',  value: 'importe'   },
        // { text: 'Descuento', value: 'descuento' , align: 'rigth'},
        // { text: 'Impuesto',  value: 'impuesto1' , align: 'rigth'},
        { text: 'Total', value: 'total', align: 'end' },
        { text: 'Divisa', value: 'divisa' },
        // { text: 'tc',    value: 'Tc' ,},

        { text: 'Estatus', value: 'status' }
      ],

      Docum: []
    }
  },

  computed: {
    ...mapGetters('Login', ['getdatosUsuario', 'getClientes', 'getModo', 'getInfoVend'])

  },

  created () {
    // Recibir parametro de tipo de Documento a Abrir.
    // console.log("Params",this.$route.params)
    // Identificar al usuario y a su cliente.
    // console.log("getdatosUsuario", this.getdatosUsuario)
    // console.log("getdatosUsuario", this.getClientes)
    this.numcli = this.getdatosUsuario.numcli
    this.tipodocumento = this.$route.params.tipodocumento
    // Definir valores iniciales
    // Fecha inicial Primero de mes.
    const date0 = new Date()
    const mes = date0.getMonth()
    const anio = date0.getFullYear()

    // console.log(mes, anio)
    this.date1 = new Date(anio, mes, 1).toISOString().substr(0, 10)
    this.consultar()
  },

  methods: {
    ...mapActions('documento', ['addDocumento']),

    // Consulto todos los clientes
    consultar () {
      this.Docum = []
      // console.log(this.tipodoc)
      var payload = {
        Numvend: '',
        Numcli: this.numcli,
        Numalm: '',
        Fecha1: this.date1,
        Fecha2: this.date2,
        Tipodoc: this.tipodoc
      }

      if (this.tipodoc == 'Pedido') {
        payload.Tipodoc = ' P'
      } else {
        payload.Tipodoc = ' Q'
      }

      this.loading = true

      // if(this.getModo == 'vendedor'){
      //   payload.numvend = this.getInfoVend.Numvend
      // }else{
      //   payload.numcli = this.getdatosUsuario.numcli
      // }

      // console.log("payload",payload)
      this.$http.post('auth/api/v1/condoctipo', payload).then(response => {
        // console.log(response)

        if (response.data === null) {
          return
        }

        // Calcular el Total.
        if (response.data.length > 0) {
          // console.log(response.data)
          this.Docum = response.data

          this.Docum.forEach(element => {
            element.importe = accounting.unformat(element.importe)
            element.impuesto1 = accounting.unformat(element.impuesto1)
            element.descuento = accounting.unformat(element.descuento)
            element.total = element.importe - element.descuento + element.impuesto1

            element.importe = accounting.formatNumber(element.importe, 2)
            element.impuesto1 = accounting.formatNumber(element.impuesto1, 2)
            element.descuento = accounting.formatNumber(element.descuento, 2)
            element.total = accounting.formatNumber(element.total, 2)
          })
          this.longitud = this.Docum.length
        }
      }).catch(function (error) {
        console.log(error)
      }).finally(() => this.loading = false)
    },

    edit (tipodoc) {
      this.addDocumento(tipodoc)
      var encodedData = base64.encode(tipodoc.Iddocum)
      var doc = { doc: encodedData }

      // this.$router.push({name:'documento', params: {info: doc, origen: 'cotizacion'}}).catch(err => {console.log(err)})
      this.$router.push({ name: 'documento', params: { info: tipodoc, origen: 'cotizacion' } }).catch(err => { console.log(err) })
    },

    getColor (status) {
      if (status == 0) return 'red'
      else if (status == 9) return 'green'
      else return 'green'
    },

    exportar () {
      const xls = {
        data: this.Docum,
        tHeaders: ['ID doc', 'Nombre', 'Tipo', 'Fecha', 'Total', 'Divisa', 'Estatus'],
        filterVal: ['Numdoc', 'nomcli', 'Tipodoc', 'Fecha', 'importe', 'divisa', 'Status'],
        nomfile: 'Consulta de Documentos'
      }
      // console.log(xls)
      this.exportarXls(xls)
    }

  }
}
</script>
